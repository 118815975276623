<template>
  <div>
    <main>
      <!-- 麵包屑 -->
      <section class="container" data-aos="fade-down" data-aos-delay="500">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb" class="badge">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
            <li class="breadcrumb-item active" aria-current="page"><router-link to="">我女兒懷孕了</router-link></li>
          </ol>
        </nav>
      </section>

      <!-- 步驟選項 -->
      <section class="container step-rwd">
        <div class="step">
          <!-- 裝飾用圖片 -->
          <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
          <img class="image1" src="../../assets/image/front/page02_image1.svg">
          <img class="image2" src="../../assets/image/front/page02_image2.svg">
          <img class="image3" src="../../assets/image/front/page02_image3.svg">
          <!-- 結束 -->
          <p class="step-text" data-aos="fade-up">STEP 02</p>
          <h2 class="step-title" data-aos="fade-up">支持・包容</h2>
          <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up">
          <p class="step-sologan" data-aos="fade-up">為家庭所想，為小孩所選。</p>
          <div class="container step-box">
            <div class="step-box-inner">
              <div class="box-title" data-aos="fade-up">
                <h2>｜我女兒懷孕了｜</h2>
              </div>
               <ul class="step-menu">
                  <!-- <li data-aos="fade-up" data-aos-delay="300">
                    <img src='../../assets/image/front/page02_pic1.jpg'>
                    <router-link to="daughter-3-1">我或我女兒有的權益保障 </router-link>
                    <p>這裡可以打簡短文案，<br>整體比較一致。</p>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="500">
                    <img src='../../assets/image/front/page02_pic2.jpg'>
                    <router-link to="daughter-3-2">如何面對外人眼光</router-link>
                    <p>這裡可以打簡短文案，<br>整體比較一致。</p>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="700">
                    <img src='../../assets/image/front/page02_pic3.jpg'>
                    <router-link to="daughter-3-3">週數多大無法中止懷孕</router-link>
                    <p>這裡可以打簡短文案，<br>整體比較一致。</p>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="300">
                    <img src='../../assets/image/front/page02_pic1.jpg'>
                    <router-link to="daughter-3-4">小孩生下就會被帶走嗎</router-link>
                    <p>這裡可以打簡短文案，<br>整體比較一致。</p>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="500">
                    <img src='../../assets/image/front/page02_pic2.jpg'>
                    <router-link to="daughter-3-5">還能完成學業嗎</router-link>
                    <p>這裡可以打簡短文案，<br>整體比較一致。</p>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="700">
                    <img src='../../assets/image/front/page02_pic3.jpg'>
                    <router-link to="daughter-3-6">小孩怎麼養小孩</router-link>
                    <p>這裡可以打簡短文案，<br>整體比較一致。</p>
                  </li> -->
                  <li data-aos="fade-up" data-aos-delay="300">
                    <img src='../../assets/image/front/page02_pic1.jpg'>
                    <router-link to="talking-3-1-1">不知道有沒有懷孕</router-link>
                    <p class="d-block">不知道女兒(孩子的女友)有沒有懷孕</p>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="500">
                    <img src='../../assets/image/front/page02_pic2.jpg'>
                    <router-link to="unintended-3-1-2">想把孩子生下來</router-link>
                    <p class="d-block">如果女兒（孩子的女友）要生下寶寶，<br>接下來會面對什麼狀況？</p>
                  </li>
                  <!-- <li data-aos="fade-up" data-aos-delay="500">
                    <img src='../../assets/image/front/page02_pic2.jpg'>
                    <router-link to="unintended-3-1-1">只能選擇生下來</router-link>
                    <p>這裡可以打簡短文案，<br>整體比較一致。</p>
                  </li> -->
                  <li data-aos="fade-up" data-aos-delay="700">
                    <img src='../../assets/image/front/page02_pic3.jpg'>
                    <router-link to="unintended-3-2-2">還沒準備好生下來</router-link>
                    <p class="d-block">我還沒準備好要讓女兒生下寶寶，<br>還能怎麼辦？</p>
                  </li>
                </ul>
                <hr data-aos="fade-up">
                <div data-aos="fade-up">
                  <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                </div>
            </div>
          </div>
        </div>
      </section>
      <Circle></Circle>
    </main>
  </div>
</template>
